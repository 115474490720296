@charset "utf-8";

/* Font Import */
/* (국문) Noto Sans KR */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&display=swap');
/* (영문) Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/*
** Font Family Info **

** Noto Sans KR | 'Noto Sans KR', sans-serif;
** font weight:
** Regular - 400;
** Medium - 500;
** Bold - 700;
** Black - 900;
*/
/*
** Poppins | 'Poppins', sans-serif;
** font weight:
** Regular - 400;
** Semi Bold - 600;
** Bold - 700;
*/

* {
    margin: 0;
    padding: 0;
    vertical-align: top;
    border: 0;
    box-sizing: border-box;
    font-size: 0;
}

html {
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: 100%;
    height: auto;
    font-size: 14px;
    line-height: 1.3;
    word-break: keep-all;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
    font-weight: 400;
    color: #fff;
    background: transparent;
    letter-spacing: -.3px;
}

body {
    width: 100%;
    height: auto;
    font-size: 14px;
    line-height: 1.3;
    word-break: keep-all;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
    font-weight: 400;
    color: #fff;
    background: #141414;
    letter-spacing: -.3px;
    overflow-x: hidden;
}

html.en {
    width: 100%;
    height: auto;
    font-size: 16px;
    line-height: 1.3;
    word-break: keep-all;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    background: transparent;
}

.en {
    font-family: 'Poppins', 'Noto Sans KR', sans-serif;
    font-weight: 400;
}

.none {
    display: none;
}

.full {
    width: 100%;
    height: 100%;
}

.blind {
    text-indent: -9999px;
    overflow: hidden;
    color: transparent
}

/* HTML5 새 요소 초기화 */
article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

textarea {
    resize: none;
}

em, address, main {
    font-style: normal;
}

table, input, textarea, select, button, pre {
    font-size: 1rem;
    line-height: 1.3;
    color: #000;
    border-radius: 0;
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

pre {
    white-space: pre-line;
}

ul, ol, li {
    list-style: none;
    padding: 0;
    margin: 0;
}

input, select, button {
    vertical-align: middle;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
}

button, select {
    cursor: pointer;
    text-align: center;
}

table, th, td {
    border-spacing: 0;
    border-collapse: collapse;
    vertical-align: middle;
    text-align: left;
}

input, textarea {
    border: none;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
}

input[type=text]::-ms-clear {
    display: none;
}

select {
    border: none;
    font-family: 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif;
}

input:focus, select:focus, textarea:focus {
    border: none;
}

/* input::placeholder {color: #ccc !important;} */
/* input::placeholder {color: rgba(0,0,0,0.5) !important;} */
/* textarea::placeholder {color: #ccc !important;} */

h1, h2, h3, h4, h5, h6 {
    font-size: 1rem;
}

a, a:link, a:hover, a:focus, a:active, a:visited {
    outline: none;
}

a:link {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}

a:visited {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}

a:hover {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}

a:active {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}

a:focus {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}

hr {
    display: none;
    height: 0;
}

img {
    width: 100%;
}

/* select {
  position: relative;
  border-radius:0;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  background: url('../images/select_arrow.png') no-repeat 96% 50%;
  background-size: 24px;
} */
select::-ms-expand {
    display: none;
}

/* 박스 */
.clfix:after, .clfix:before {
    line-height: 0;
    display: table;
    content: ''
}

.clfix:after {
    clear: both
}

.nolist-left::after, .nolist-right::after, .nolist-center::after, .nolist::after, .clear::after {
    content: '';
    display: block;
    clear: both;
    width: 0;
    height: 0;
}

.nolist-center {
    text-align: center;
    font-size: 0;
}

.nolist-center > * {
    display: inline-block;
    font-size: 1rem;
}

.nolist-left > * {
    float: left;
}

.nolist-right > * {
    float: right;
}

.left {
    float: left;
}

.right {
    float: right;
}

.clear > .col-2, .nolist > .col-2, .nolist-left > .col-2, .nolist-right > .col-2, .nolist-center > .col-2 {
    width: 50%;
}

.clear > .col-3, .nolist > .col-3, .nolist-left > .col-3, .nolist-right > .col-3, .nolist-center > .col-3 {
    width: 33.3%;
}

.clear > .col-4, .nolist > .col-4, .nolist-left > .col-4, .nolist-right > .col-4, .nolist-center > .col-4 {
    width: 25%;
}

.clear > .col-5, .nolist > .col-5, .nolist-left > .col-5, .nolist-right > .col-5, .nolist-center > .col-5 {
    width: 20%;
}

.clear > .col-6, .nolist > .col-6, .nolist-left > .col-6, .nolist-right > .col-6, .nolist-center > .col-6 {
    width: 16.66%;
}

.clear > .col-7, .nolist > .col-7, .nolist-left > .col-7, .nolist-right > .col-7, .nolist-center > .col-7 {
    width: 14.28%;
}

.clear > .col-8, .nolist > .col-8, .nolist-left > .col-8, .nolist-right > .col-8, .nolist-center > .col-8 {
    width: 12.5%;
}

.dim {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999998;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
}

/* bg 이미지 초기화 */
.bg, .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.image {
    min-width: 1rem;
    min-height: 1rem;
}

.bg.cover {
    background-size: cover;
}

.bg.auto {
    background-size: auto;
}

/* 중앙정렬 */
.relative {
    position: relative;
}

.vhcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
}

/* 가로세로 */
.hcenter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    vertical-align: middle;
}

/* 가로 */
.vcenter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
}

/* 세로 */

.Wbar {
    display: inline-block;
    width: 30px;
    height: 2px;
    background-color: #333;
    margin: 20px 0;
}

.ellipsis-row {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
    width: 100%;
}

.ellipsis-many {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 3em;
    line-height: 1.5em;
}

/* font weight */
.Tbold {
    font-weight: 700 !important;
}

.Tregular {
    font-weight: 400 !important;
}

.Tlight {
    font-weight: 300 !important;
}

/* font color */
.col_wh, a.col_wh {
    color: #fff !important;
}

.col_bl, a.col_bl {
    color: #000 !important;
}

.color-blue {
    color: #4676fd !important;
}

/* background color */
.bg_wh {
    background-color: #fff !important;
}

.bg_bl {
    background-color: #000 !important;
}

/* icon 관련 */
.icon_wrap {
    display: inline-block;
    width: 100%;
}

/* max-width로 크기 설정 */
.icon_wrap > img {
    width: 100%;
    height: auto;
}

/* image 관련 */
.img_wrap {
    position: relative;
    display: block;
    font-size: 0;
}

/* width로 크기 설정 */
.img_wrap > img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}

.img_dim {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
}

/* Header Active Event */
.subpage .sub-title-wrap .st-box {
    margin: 0 auto 16px;
}

.subpage .sub-title-wrap .st-box.active {
    margin: 0 auto 126px;
}

.subpage .sub-title-wrap .sec-tab-box {
    width: 100%;
    left: 0;
    transform: translate(0, 0);
}

.subpage .sub-title-wrap .sec-tab-box.active {
    position: fixed;
    top: 96px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #141414;
    border-top: 1px solid #222;
    border-bottom: none;
    z-index: 10;
}

.subpage .sub-title-wrap .sec-tab-box.active .wrapper .tab-list.active {
    border-bottom: none;
}

.subpage .sub-title-wrap .sec-tab-box.active .wrapper .tab-list.active a {
    color: #ff0086;
}

/* Header Active Event */

@charset "utf-8";

/* White */

body {
    background: black;
}

.p-color {
    color: #ff0086 !important
}

.tab {
    border-bottom: 1px solid #f3f3f3;
}

.tab ol {
    padding: 0 24px;
}

.tab ol li {
    float: left;
}

.tab ol li a {
    font-size: 15px;
    line-height: 46px;
    letter-spacing: -.3px;
    color: #aaa;
    display: block;
    text-align: center;
    height: 46px;
    font-weight: 600;
}

.tab ol li.on a {
    color: #000;
    border-bottom: 2px solid #000
}

.tab.type-1 ol li {
    width: 100%;
}

.tab.type-2 ol li {
    width: 50%;
}

.tab.type-3 ol li {
    width: 33.333333%;
}

.no-list {
    padding: 192px 0;
}

.no-list p {
    font-size: 16px;
    line-height: 28px;
    color: #ddd;
    letter-spacing: -.3px;
    padding-top: 80px;
    position: relative;
    text-align: center;
}

.no-list p:before {
    content: '';
    width: 64px;
    height: 64px;
    /*background: url('./images/icon-result-64-no-result_gray.png') no-repeat top center;*/
    background-size: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.dim {
    background: rgba(0, 0, 0, .8);
}

.payment-wrap {
    margin-top: 24px
}

.payment-wrap h2 {
    font-size: 20px;
    line-height: 28px;
    color: #000;
    letter-spacing: -.3px;
    font-weight: 600;
    padding: 0 24px;
    margin-bottom: 12px
}

.payment-wrap .plan-box {
    padding: 0 24px;
    margin: 16px 0 36px;
}

.payment-wrap .plan-box .plan-inner {
    padding: 20px 24px 24px;
    border-radius: 8px;
    background-color: #f6f6f6;
}

.payment-wrap .plan-box .plan-inner .plan-top-box {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e3e3e3
}

.payment-wrap .plan-box .plan-inner .plan-top-box .tit-box {
    margin-bottom: 6px
}

.payment-wrap .plan-box .plan-inner .plan-top-box .tit-box strong {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -.3px;
    color: #000
}

.payment-wrap .plan-box .plan-inner .plan-top-box .tit-box p {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -.3px;
    text-align: right;
    font-weight: 600;
    color: #000
}

.payment-wrap .plan-box .plan-inner .plan-top-box .tit-box p span {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: #666;
    font-weight: 400;
}

.payment-wrap .plan-box .plan-inner .plan-top-box .noti-txt {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -.3px;
}

.payment-wrap .plan-box .plan-inner .plan-bottom-box .info-box {
    margin-bottom: 20px;
}

.payment-wrap .plan-box .plan-inner .plan-bottom-box .info-box dl {
    display: block;
}

.payment-wrap .plan-box .plan-inner .plan-bottom-box .info-box dl dt {
    display: inline-block;
    width: 80px;
    vertical-align: top;
    font-size: 14px;
    line-height: 26px;
    color: #888;
    letter-spacing: -.3px;
}

.payment-wrap .plan-box .plan-inner .plan-bottom-box .info-box dl dd {
    display: inline-block;
    width: calc(100% - 80px);
    vertical-align: top;
    font-size: 14px;
    line-height: 26px;
    color: #000;
    letter-spacing: -.3px;
}

.payment-wrap .plan-box .plan-inner .plan-bottom-box .change-btn {
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #ff0086;
    font-size: 14px;
    line-height: 48px;
    font-weight: 600;
    border-radius: 4px;
}

.payment-wrap .plan-box .plan-inner .plan-bottom-box .cancel-btn {
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #aaa;
    font-size: 14px;
    line-height: 36px;
    border-radius: 4px;
    margin-top: 12px
}

.payment-wrap .line-box {
    border-top: 1px solid #f3f3f3;
    margin-bottom: 36px
}

.payment-wrap .line-box dl {
    padding: 16px 24px;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
}

.payment-wrap .line-box dl dt {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 70px);
}

.payment-wrap .line-box dl dt strong,
.payment-wrap .line-box dl dt p {
    font-size: 15px;
    line-height: 24px;
    color: #000;
    letter-spacing: -.3px;
    display: block;
}

.payment-wrap .line-box dl dt img {
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    vertical-align: top;
}

.payment-wrap .line-box dl dt .img-txt-box {
    display: inline-block;
    width: calc(100% - 60px);
    margin-left: 12px;
    vertical-align: top;
}

.payment-wrap .line-box dl dt .img-txt-box span {
    display: block;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -.3px;
}

.payment-wrap .line-box dl dt .img-txt-box em {
    display: block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -.3px;
    color: #666;
    margin-top: 4px
}

.payment-wrap .line-box dd {
    display: inline-block;
    vertical-align: top;
    width: 70px;
    text-align: right;
    align-self: center
}

.payment-wrap .line-box dd a {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #999;
    letter-spacing: -.3px;
}

.payment-wrap.no-data .plan-box .plan-inner .plan-bottom-box p {
    padding: 8px 0 24px;
    font-size: 14px;
    line-height: 26px;
    color: #666;
    letter-spacing: -.3px;
    text-align: center;
}

.payment-wrap.no-data .line-box p {
    padding: 40px 0;
    font-size: 14px;
    line-height: 20px;
    color: #aaa;
    letter-spacing: -.3px;
    text-align: center;
    border-bottom: 1px solid #f3f3f3
}

.plan-change {
    padding: 0 24px;
    margin-top: 24px
}

.plan-change h2 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -.3px;
    color: #000;
    text-align: center;
}

.plan-change h2 i {
    display: inline-block;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -.3px;
    color: #666;
}

.plan-change h2 span {
    display: inline-block;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -.3px;
}

.plan-change .plan-box {
    margin-top: 24px;
    padding: 20px 24px;
    background-color: #f6f6f6;
    border-radius: 8px;
}

.plan-change .plan-box dl {
    display: block;
}

.plan-change .plan-box dl dt {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 60px);
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -.3px;
    color: #000
}

.plan-change .plan-box dl dd {
    display: inline-block;
    vertical-align: top;
    width: 60px;
    text-align: right;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -.3px;
    font-weight: 600;
    color: #00d1d9
}

.plan-change .plan-box dl.impossible dt {
    color: #aaa
}

.plan-change .plan-box dl.impossible dd {
    color: #ff0086
}

.plan-change .fix-btn {
    position: fixed;
    width: calc(100% - 48px);
    bottom: 34px;
}

.plan-change .fix-btn .change-btn {
    display: block;
    text-align: center;
    padding: 10px 12px;
    background-color: #ff0086;
    border-radius: 34px;
    width: 100%;
}

.plan-change .fix-btn .change-btn strong {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    letter-spacing: -.3px;
}

.plan-change .fix-btn .change-btn span {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    letter-spacing: -.3px;
}

.plan-change .fix-btn .new-add-btn {
    display: block;
    margin-top: 12px;
    width: 100%;
    height: 48px;
    line-height: 46px;
    font-size: 14px;
    color: #000;
    letter-spacing: -.3px;
    border: 1px solid #eee;
    border-radius: 24px;
    text-align: center;
}

.history-wrap .history-sorting {
    padding: 16px 24px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;
}

.history-wrap .history-sorting .front-sorting .btn-box {
    display: inline-block;
    width: 130px;
    position: relative;
}

.history-wrap .history-sorting .front-sorting .btn-box:before {
    content: '';
    width: 24px;
    height: 24px;
    /*background: url('../img/icon-input-dropdown-down-small.png') no-repeat;*/
    background-size: contain;
    position: absolute;
    top: 6px;
    right: 6px;
    transition: .3s;
    transform: rotate(0deg);
    pointer-events: none;
}

.history-wrap .history-sorting .front-sorting .btn-box.on:before {
    transform: rotate(180deg);
}

.history-wrap .history-sorting .front-sorting .btn-box button {
    display: block;
    width: 100%;
    line-height: 36px;
    border-radius: 4px;
    padding-left: 12px;
    background-color: #f6f6f6;
    font-size: 14px;
    color: #000;
    letter-spacing: -.3px;
    text-align: left;
}

.history-wrap .history-sorting .front-sorting p {
    display: inline-block;
    font-size: 14px;
    line-height: 36px;
    color: #aaa;
    letter-spacing: -.3px;
    margin-left: 10px;
}

.history-wrap .history-sorting .sorting-list {
    border-radius: 4px;
    position: absolute;
    top: 52px;
    left: 24px;
    overflow: hidden;
    display: none;
}

.history-wrap .history-sorting .sorting-list.open {
    display: block;
}

.history-wrap .history-sorting .sorting-list ul {
    height: 180px;
    overflow-y: scroll;
}

.history-wrap .history-sorting .sorting-list ul li {
    width: 130px;
}

.history-wrap .history-sorting .sorting-list ul li button {
    display: inline-block;
    width: 100%;
    line-height: 36px;
    padding-left: 12px;
    background-color: #f6f6f6;
    font-size: 14px;
    color: #000;
    letter-spacing: -.3px;
    text-align: left;
}

.history-wrap .history-list ol li {
    padding: 16px 24px;
    border-bottom: 1px solid #f3f3f3;
}

.history-wrap .history-list ol li .left-box {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 100px);
}

.history-wrap .history-list ol li .left-box span {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #aaa;
    letter-spacing: -.3px;
    font-weight: 600;
}

.history-wrap .history-list ol li .left-box strong {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    letter-spacing: -.3px;
    font-weight: 400;
}

.history-wrap .history-list ol li .left-box p {
    display: block;
    font-size: 12px;
    line-height: 24px;
    color: #aaa;
    letter-spacing: -.3px;
}

.history-wrap .history-list ol li .right-box {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    text-align: right;
}

.history-wrap .history-list ol li .right-box span {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #aaa;
    letter-spacing: -.3px;
    font-weight: 600;
}

.history-wrap .history-list ol li .right-box strong {
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -.3px;
}

.plan-choice {
    position: relative;
    padding: 24px 24px 0;
}

.plan-choice .plan-choice-box .select-box {
    margin-bottom: 12px
}

.plan-choice .plan-choice-box .select-box:last-of-type {
    margin-bottom: 0
}

.plan-choice .plan-choice-box .select-box input {
    border: 0;
    padding: 0;
    font-size: 0;
    line-height: inherit;
    color: transparent;
    letter-spacing: 0;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
    border-radius: 0;
}

.plan-choice .plan-choice-box .select-box input[type="radio"]:checked + label {
    border-color: #ff0086
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label {
    padding: 20px 24px;
    border: 1px solid #eee;
    border-radius: 8px;
    text-align: center;
    display: block
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label .plan-tit {
    margin-bottom: 10px
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label .plan-tit strong {
    display: inline-block;
    vertical-align: top;
    width: 120px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -.3px;
    color: #000;
    text-align: left;
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label .plan-tit p {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 120px);
    text-align: right;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -.3px;
    color: #666;
    font-weight: 600;
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label .plan-tit p span {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label .plan-tit p em {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label ol li {
    padding-left: 16px;
    font-size: 13px;
    line-height: 16px;
    color: #999;
    position: relative;
    margin-bottom: 4px;
    float: left;
    width: 50%;
    text-align: left;
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label ol li:last-of-type {
    margin-bottom: 0;
}

.plan-choice .plan-choice-box .select-box input[type="radio"] + label ol li:before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #aaa;
    top: 6px;
    left: 6px;
    position: absolute;
    border-radius: 50%;
}

.plan-choice .fix-btn {
    position: fixed;
    width: calc(100% - 48px);
    bottom: 34px;
}

.plan-choice .fix-btn .info-txt {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    letter-spacing: -.3px;
    margin-bottom: 12px
}

.plan-choice .fix-btn .info-txt span {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.3px;
    display: inline-block;
}

.plan-choice .fix-btn .info-txt em {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.3px;
    display: inline-block;
    color: #999
}

.plan-choice .fix-btn .next-btn {
    display: block;
    width: 100%;
    line-height: 56px;
    color: #fff;
    letter-spacing: -.3px;
    text-align: center;
    font-size: 16px;
    background-color: #ff0086;
    border-radius: 28px;
    font-weight: 600;
}

.fc-signup {
    padding: 24px 24px 0;
}

.fc-signup h2 {
    font-size: 20px;
    line-height: 28px;
    color: #000;
    letter-spacing: -.3px;
    margin-bottom: 8px
}

.fc-signup h2:first-of-type {
    margin-bottom: 16px;
}

.fc-signup .fc-signup-box {
    margin-bottom: 40px
}

.fc-signup .fc-signup-box .select-box {
    margin-bottom: 12px
}

.fc-signup .fc-signup-box .select-box:last-of-type {
    margin-bottom: 0
}

.fc-signup .fc-signup-box .select-box input {
    border: 0;
    padding: 0;
    font-size: 0;
    line-height: inherit;
    color: transparent;
    letter-spacing: 0;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
    border-radius: 0;
}

.fc-signup .fc-signup-box .select-box input[type="radio"]:checked + label {
    border: 2px solid #ff0086;
    padding: 19px 23px
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label {
    padding: 20px 24px;
    border: 1px solid #eee;
    border-radius: 8px;
    text-align: center;
    display: flex
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box {
    width: calc(100% - 32px);
    vertical-align: top;
    display: inline-block;
    text-align: left;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box strong {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    letter-spacing: -.3px;
    position: relative;
}

.fc-signup .fc-signup-box .select-box.best input[type="radio"] + label .left-box strong:after {
    content: 'BEST';
    font-size: 10px;
    line-height: 18px;
    padding: 0 8px;
    border-radius: 8px;
    background-color: #ff0086;
    margin-left: 8px;
    text-align: center;
    color: #fff;
    letter-spacing: -.3px;
    display: inline-block;
    vertical-align: 2px;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box span {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: -.3px;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box span em {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: #999 !important;
    font-weight: 400;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #999;
    letter-spacing: -.3px;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box .noti-txt {
    margin-top: 8px;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -.3px;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .right-box {
    width: 32px;
    display: inline-block;
    vertical-align: top;
    align-self: center;
}

.fc-signup .fc-signup-box .select-box input[type="radio"] + label .right-box .chk-icon {
    width: 100%;
    height: 32px;
    border-radius: 50%;
    background-color: #f3f3f3;
}

.fc-signup .fc-signup-box .select-box input[type="radio"]:checked + label .right-box .chk-icon {
    /*background: url('../img/icon-chat-photo-select.png') no-repeat #ff0086 center;*/
    background-size: 24px;
}

.fc-signup .agree-list {
    margin-bottom: 40px
}

.fc-signup .agree-list .agree-box {
    padding: 12px 0;
    border-bottom: 1px solid #f3f3f3
}

.fc-signup .agree-list .agree-box:last-of-type {
    border-bottom: 0
}

.fc-signup .agree-list .agree-box input {
    border: 0;
    padding: 0;
    font-size: 0;
    line-height: inherit;
    color: transparent;
    letter-spacing: 0;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
    border-radius: 0;
}

.fc-signup .agree-list .agree-box input[type="checkbox"]:checked + label:after {
    content: '';
    /*background: url('../img/btn-checkbox-on.png') no-repeat;*/
    background-size: contain;
}

.fc-signup .agree-list .agree-box input[type="checkbox"] + label {
    display: block;
    width: 100%;
    padding-left: 34px;
    position: relative;
}

.fc-signup .agree-list .agree-box input[type="checkbox"] + label:after {
    content: '';
    width: 24px;
    height: 24px;
    /*background: url('../img/btn-checkbox-off-web-mypage2.png') no-repeat;*/
    background-size: contain;
    position: absolute;
    top: 6px;
    left: 0
}

.fc-signup .agree-list .agree-box input[type="checkbox"] + label .left-box {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 36px);
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    color: #000;
    letter-spacing: -.3px;
}

.fc-signup .agree-list .agree-box input[type="checkbox"] + label .left-box span {
    display: inline-block;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: -.3px;
    color: #999
}

.fc-signup .agree-list .agree-box input[type="checkbox"] + label .right-box {
    display: inline-block;
    vertical-align: top;
    width: 36px;
    text-align: right;
}

.fc-signup .agree-list .agree-box input[type="checkbox"] + label .right-box a {
    display: block;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: -.3px;
    color: #999
}

.fc-signup .next-btn {
    display: block;
    width: 100%;
    line-height: 56px;
    color: #fff;
    letter-spacing: -.3px;
    text-align: center;
    font-size: 16px;
    background-color: #ff0086;
    border-radius: 28px;
    font-weight: 600;
}

.fc-signup .pay-list .pay-box {
    padding: 12px 0;
    border-bottom: 1px solid #f3f3f3
}

.fc-signup .pay-list .pay-box input.radio-input {
    border: 0;
    padding: 0;
    font-size: 0;
    line-height: inherit;
    color: transparent;
    letter-spacing: 0;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
    border-radius: 0;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input:checked + label:after {
    content: '';
    /*background: url('../img/btn-radio-on.png') no-repeat;*/
    background-size: contain;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input:checked + label .hide-box {
    display: block;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label {
    display: block;
    width: 100%;
    position: relative;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label:after {
    content: '';
    width: 24px;
    height: 24px;
    /*background: url('../img/btn-radio-off.png') no-repeat;*/
    background-size: contain;
    position: absolute;
    top: 6px;
    left: 0
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .front-box {
    padding-left: 34px;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .front-box p {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -.3px;
    color: #000;
    line-height: 36px;
    font-weight: 600;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box {
    padding: 11px 0 24px;
    display: none;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box {
    padding-left: 34px;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box p {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.3px;
    color: #000
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.google-play-img {
    /*background-image: url('../img/logo-checkout-google-play.png');*/
    background-size: contain;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.domoco-img {
    /*background-image: url('../img/logo-checkout-docomo.png');*/
    background-size: contain;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.soft-bank-img {
    /*background-image: url('../img/logo-checkout-soft-bank.png');*/
    background-size: contain;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.linepay-img {
    /*background-image: url('../img/logo-checkout-linepay.png');*/
    background-size: contain;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.pay-pay-img {
    /*background-image: url('../img/logo-checkout-pay-pay.png');*/
    background-size: contain;
}

.fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .next-btn {
    margin-top: 36px
}

.fc-signup .pay-list .pay-box .card-img-box img {
    display: inline-block;
    width: 33px;
    margin-right: 4px;
}

.fc-signup .pay-list .pay-box .card-img-box img:last-of-type {
    margin-right: 0;
}

.fc-signup .pay-list .pay-box .inp-box {
    margin-top: 24px
}

.fc-signup .pay-list .pay-box .inp-box p {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    letter-spacing: -.3px;
    font-weight: 600;
}

.fc-signup .pay-list .pay-box .inp-box .card-num input {
    margin-top: 12px;
    height: 40px;
    line-height: 38px;
    width: 25%;
    color: #000;
    letter-spacing: -.3px;
    font-size: 14px;
    text-align: center;
}

.fc-signup .pay-list .pay-box .inp-box .card-num input:nth-child(1) {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    padding-left: 12px;
    border-right: 0;
}

.fc-signup .pay-list .pay-box .inp-box .card-num input:nth-child(2) {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 0;
    border-right: 0;
}

.fc-signup .pay-list .pay-box .inp-box .card-num input:nth-child(3) {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 0;
    border-right: 0;
}

.fc-signup .pay-list .pay-box .inp-box .card-num input:nth-child(4) {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-right: 12px;
    border-left: 0;
}

.fc-signup .pay-list .pay-box .inp-box input {
    margin-top: 12px;
    border: 1px solid #eee;
    height: 40px;
    line-height: 38px;
    width: 100%;
    padding: 0 12px;
    color: #000;
    letter-spacing: -.3px;
    font-size: 14px
}

.fc-signup .pay-list .pay-box .inp-box input::placeholder {
    color: #ccc
}

.fc-signup .pay-list .pay-box .inp-box select {
    margin-top: 12px;
    border: 1px solid #eee;
    height: 40px;
    line-height: 38px;
    width: 100%;
    padding: 0 12px;
    color: #000;
    letter-spacing: -.3px;
    font-size: 14px;
    text-align: left;
}

.fc-signup .pay-list .pay-box .inp-box select * {
    font-size: 14px
}

.fc-signup .pay-list .pay-box .inp-box .noti-txt {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    color: #999;
    letter-spacing: -.3px;
    margin-top: 8px;
    display: block;
}

.fc-signup .pay-list .pay-box .inp-box .noti-txt:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #aaa;
    display: inline-block;
    margin: 0 6px;
    vertical-align: middle;
}

.fc-signup .pay-list .pay-box .row-box .inp-box {
    display: inline-block;
    vertical-align: top;
    width: 66.6666%;
}

.fc-signup .pay-list .pay-box .row-box .inp-box:nth-child(2) {
    width: calc(33.3334% - 12px);
    margin-left: 12px;
}

.fc-signup .pay-list .pay-box .row-box .inp-box select:nth-child(3) {
    margin-left: 12px;
}

.fc-signup .pay-list .pay-box .row-box .inp-box select {
    width: calc(50% - 6px);
}

.fc-signup .pay-list .pay-box .row-box .noti-box {
    margin-top: 24px
}

.fc-signup .pay-list .pay-box .row-box .noti-box p {
    font-size: 12px;
    line-height: 18px;
    color: #999;
    letter-spacing: -.3px;
}

.fc-signup .pay-list .pay-box .row-box .noti-box p strong {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    color: #999;
    letter-spacing: -.3px;
}

.fc-signup .sub-tit {
    font-size: 14px;
    line-height: 20px;
    color: #999;
    letter-spacing: -.3px;
    margin-bottom: 16px;
}

.fc-signup .fc-signup-result {
    min-height: 428px;
}

.fc-signup .fc-signup-result .result-box {
    padding: 20px 24px;
    background-color: #fafafa;
    border-radius: 8px;
    margin-bottom: 12px
}

.fc-signup .fc-signup-result .result-box:last-of-type {
    margin-bottom: 0
}

.fc-signup .fc-signup-result .result-box dl {
    display: block;
    margin-bottom: 16px
}

.fc-signup .fc-signup-result .result-box dl:last-of-type {
    margin-bottom: 0
}

.fc-signup .fc-signup-result .result-box dl dt {
    font-size: 12px;
    line-height: 18px;
    color: #aaa;
    letter-spacing: -.3px;
    font-weight: 600;
    margin-bottom: 4px;
}

.fc-signup .fc-signup-result .result-box dl dd {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    letter-spacing: -.3px;
    font-weight: 600;
}

.fc-signup .fc-signup-result .result-box dl dd span {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    letter-spacing: -.3px;
}

.fc-signup .bot-btn {
    position: relative;
    margin-top: 24px
}

.fc-signup .bot-btn .submit-btn {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -.3px;
    line-height: 56px;
    border-radius: 28px;
    background-color: #ff0086;
    color: #fff;
    text-align: center;
}

.fc-signup .bot-btn .modify-btn {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -.3px;
    line-height: 54px;
    height: 56px;
    border-radius: 28px;
    border: 1px solid #ddd;
    color: #aaa;
    text-align-last: center;
    margin-top: 12px
}

.fc-signup .finish-box {
    position: absolute;
    top: calc(50% - 36px);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding-top: 144px;
    /*background: url('../img/icon-complete-white.png') no-repeat center top;*/
    background-size: 104px;
}

.fc-signup .finish-box p {
    font-size: 28px;
    line-height: 36px;
    color: #000;
    font-weight: 600;
}

.fc-signup .fix-btn {
    position: fixed;
    width: calc(100% - 48px);
    bottom: 34px
}

.fc-signup .fix-btn .submit-btn {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -.3px;
    line-height: 56px;
    border-radius: 28px;
    background-color: #ff0086;
    color: #fff;
    text-align: center;
}

.privacy-wrap {
    padding: 42px 24px 28px
}

.privacy-wrap p {
    font-size: 13px;
    line-height: 20px;
    color: #666;
    letter-spacing: -.3px;
    word-break: break-all;
}

.loading-ani {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999;
}

.loading-ani .ani-img {
    width: 80px;
    margin: 0 auto;
}

.loading-ani .ani-img img {
    animation: rotate_image 1s ease-in-out infinite;
    transform-origin: 50% 50%;
}

.loading-ani p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    letter-spacing: -.3px;
    margin-top: 24px;
}


/* Black */

body.dark {
    background: #000;
}

.dark .tab {
    border-bottom: 1px solid #151515;
}

.dark .tab ol li a {
    color: rgba(255, 255, 255, .3)
}

.dark .tab ol li.on a {
    color: #fff;
    border-bottom: 2px solid #fff
}

.dark .no-list p {
    color: #444;
}

.dark .no-list p:before {
    /*background: url('../img/icon-result-64-no-result_darkgray.png') no-repeat top center;*/
    background-size: contain;
}

.dark .payment-wrap h2 {
    color: #fff
}

.dark .payment-wrap .plan-box .plan-inner {
    background-color: #272727;
}

.dark .payment-wrap .plan-box .plan-inner .plan-top-box {
    border-bottom: 1px solid #333
}

.dark .payment-wrap .plan-box .plan-inner .plan-top-box .tit-box strong {
    color: #fff
}

.dark .payment-wrap .plan-box .plan-inner .plan-top-box .tit-box p {
    color: #fff
}

.dark .payment-wrap .plan-box .plan-inner .plan-bottom-box .info-box dl dd {
    color: #fff
}

.dark .payment-wrap .plan-box .plan-inner .plan-bottom-box .cancel-btn {
    background-color: #444
}

.dark .payment-wrap .line-box {
    border-top: 1px solid #151515
}

.dark .payment-wrap .line-box dl {
    border-bottom: 1px solid #151515;
}

.dark .payment-wrap .line-box dl dt strong,
.dark .payment-wrap .line-box dl dt p {
    color: #fff
}

.dark .payment-wrap.no-data .plan-box .plan-inner .plan-bottom-box p {
    color: rgba(255, 255, 255, .3);
}

.dark .payment-wrap.no-data .line-box p {
    color: #aaa;
    border-bottom: 1px solid #151515
}

.dark .plan-change h2 {
    color: #fff;
}

.dark .plan-change .plan-box {
    background-color: #272727;
}

.dark .plan-change .plan-box dl dt {
    color: #fff
}

.dark .plan-change .plan-box dl.impossible dt {
    color: #666
}

.dark .plan-change .fix-btn .new-add-btn {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, .2);
}

.dark .history-wrap .history-sorting {
    border-bottom: 1px solid #151515;
}

.dark .history-wrap .history-sorting .front-sorting .btn-box:before {
    /*background: url('../img/icon-input-dropdown-down-small-w.png') no-repeat;*/
    background-size: contain
}

.dark .history-wrap .history-sorting .front-sorting .btn-box button {
    background-color: #272727;
    color: #fff
}

.dark .history-wrap .history-sorting .front-sorting p {
    color: #666
}

.dark .history-wrap .history-sorting .sorting-list ul li button {
    background-color: #272727;
    color: #fff
}

.dark .history-wrap .history-list ol li {
    border-bottom: 1px solid #151515;
}

.dark .history-wrap .history-list ol li .left-box span {
    color: #666
}

.dark .history-wrap .history-list ol li .left-box strong {
    color: #fff
}

.dark .history-wrap .history-list ol li .left-box p {
    color: #666
}

.dark .history-wrap .history-list ol li .right-box span {
    color: #666
}

.dark .plan-choice .plan-choice-box .select-box input[type="radio"]:checked + label {
    border-color: #ff0086
}

.dark .plan-choice .plan-choice-box .select-box input[type="radio"] + label {
    border: 1px solid #444
}

.dark .plan-choice .plan-choice-box .select-box input[type="radio"] + label .plan-tit strong {
    color: #fff;
}

.dark .plan-choice .fix-btn .info-txt {
    color: #fff;
}

.dark .fc-signup h2 {
    color: #fff
}

.dark .fc-signup .fc-signup-box .select-box input[type="radio"]:checked + label {
    border: 2px solid #ff0086
}

.dark .fc-signup .fc-signup-box .select-box input[type="radio"] + label {
    border: 1px solid #444
}

.dark .fc-signup .fc-signup-box .select-box input[type="radio"] + label .left-box strong {
    color: #fff;
}

.dark .fc-signup .fc-signup-box .select-box input[type="radio"] + label .right-box .chk-icon {
    background-color: #333;
}

.dark .fc-signup .fc-signup-box .select-box input[type="radio"]:checked + label .right-box .chk-icon {
    /*background: url('../img/icon-chat-photo-select.png') no-repeat #ff0086 center;*/
    background-size: 24px
}

.dark .fc-signup .agree-list .agree-box {
    border-bottom: 1px solid #151515
}

.dark .fc-signup .agree-list .agree-box input[type="checkbox"]:checked + label:after {
    /*background: url('../img/btn-checkbox-on.png') no-repeat;*/
    background-size: contain;
}

.dark .fc-signup .agree-list .agree-box input[type="checkbox"] + label:after {
    /*background: url('../img/btn-checkbox-off-web-mypage3.png') no-repeat;*/
    background-size: contain;
}

.dark .fc-signup .agree-list .agree-box input[type="checkbox"] + label .left-box {
    color: #fff;
}

.dark .fc-signup .pay-list .pay-box {
    border-bottom: 1px solid #151515
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input:checked + label:after {
    /*background: url('../img/btn-radio-on.png') no-repeat;*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label:after {
    /*background: url('../img/btn-radio-off-on-black.png') no-repeat;*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .front-box p {
    color: #fff
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box p {
    color: #fff
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.google-play-img {
    /*background-image: url('../img/logo-checkout-google-play-dark.png');*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.domoco-img {
    /*background-image: url('../img/logo-checkout-docomo-dark.png');*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.soft-bank-img {
    /*background-image: url('../img/logo-checkout-soft-bank-dark.png');*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.linepay-img {
    /*background-image: url('../img/logo-checkout-linepay-dark.png');*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box input[type="radio"].radio-input + label .hide-box .inner-box img.pay-pay-img {
    /*background-image: url('../img/logo-checkout-pay-pay-dark.png');*/
    background-size: contain;
}

.dark .fc-signup .pay-list .pay-box .inp-box p {
    color: #fff
}

.dark .fc-signup .pay-list .pay-box .inp-box .card-num input {
    background-color: #fff;
}

.dark .fc-signup .pay-list .pay-box .inp-box select {
    background-color: #fff;
}

.dark .fc-signup .pay-list .pay-box .inp-box .noti-txt {
    color: #999
}

.dark .fc-signup .pay-list .pay-box .inp-box .noti-txt:before {
    content: '';
    background-color: #aaa
}

.dark .fc-signup .pay-list .pay-box .inp-box input {
    background-color: #fff;
}

.dark .fc-signup .pay-list .pay-box .row-box .noti-box p {
    color: #666
}

.dark .fc-signup .pay-list .pay-box .row-box .noti-box p strong {
    color: #666
}

.dark .fc-signup .sub-tit {
    color: #666
}

.dark .fc-signup .fc-signup-result .result-box {
    background-color: #272727;
}

.dark .fc-signup .fc-signup-result .result-box dl dt {
    color: #888
}

.dark .fc-signup .fc-signup-result .result-box dl dd {
    color: #fff
}

.dark .fc-signup .fc-signup-result .result-box dl dd span {
    color: #666
}

.dark .fc-signup .bot-btn .modify-btn {
    border: 1px solid #444
}

.dark .fc-signup .finish-box {
    /*background: url('../img/icon-complete.png') no-repeat center top;*/
    background-size: 104px;
}

.dark .fc-signup .finish-box p {
    color: #fff;
}

.dark .privacy-wrap p {
    color: #aaa
}


/* landing */

.landing .header h1 {
    padding: 14px 0;
    transition: .2s;
    opacity: 1;
}

.landing .header h1.hide {
    opacity: 0;
}

.landing .header h1 a {
    display: block;
    width: 138px;
    height: 48px;
    background: url('./images/m_img-brand-mnet-bi-big.png') no-repeat;
    background-size: contain;
    margin: 0 auto;
}

.landing .header .nav-header {
    padding: 14px 0;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
    top: -60px;
    width: 100%;
    z-index: 10;
    transition: .3s;
}

.landing .header .nav-header.show {
    top: 0
}

.landing .header .nav-header a {
    display: block;
    width: 91px;
    height: 32px;
    background: url('./images/m_img-brand-mnet-bi.png') no-repeat;
    background-size: contain;
    margin: 0 auto;
}

.landing .fix-header {
    padding: 10px 24px;
    background-color: #fff;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

.landing .fix-header .left-box {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 106px);
}

.landing .fix-header .left-box img {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: top;
    border-radius: 6px;
}

.landing .fix-header .left-box p {
    display: inline-block;
    width: calc(100% - 56px);
    margin-left: 16px;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    letter-spacing: -.3px;
}

.landing .fix-header .left-box p span {
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: #666;
    letter-spacing: -.3px;
}

.landing .fix-header .right-box {
    display: inline-block;
    vertical-align: top;
    width: 71px;
    margin: 4px 0 4px 35px;
}

.landing .fix-header .right-box a {
    display: block;
    width: 100%;
    line-height: 32px;
    text-align: center;
    color: #fff;
    letter-spacing: -.3px;
    font-size: 14px;
    background-color: #ff0086;
    border-radius: 16px;
}

.landing .fix-header .right-box .blue-btn {
    width: 56px;
    background-color: #3581ff;
    margin-left: auto;
}

.landing .landing-wrap {
    padding-bottom: 60px
}

.landing .landing-wrap .landing-box .tit-box {
    padding: 0 24px;
    text-align: center;
    margin-top: 120px
}

.landing .landing-wrap .landing-box .tit-box h2 {
    font-size: 32px;
    color: #fff;
    letter-spacing: -.3px;
    line-height: 44px;
}

.landing .landing-wrap .landing-box .tit-box h2 span {
    display: inline-block;
    font-size: 32px;
    color: #ff0086;
    letter-spacing: -.3px;
    line-height: 44px;
}

.landing .landing-wrap .landing-box .tit-box h3 {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    letter-spacing: -.3px;
    margin-top: 30px
}

.landing .landing-wrap .landing-box .tit-box h3 span {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    color: #ff0086;
    letter-spacing: -.3px;
}

.landing .landing-wrap .landing-box .tit-box p {
    margin-top: 16px;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -.3px;
    color: #999
}

.landing .landing-wrap .landing-box .tit-box img {
    width: 96px;
    height: 96px;
    display: inline-block;
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.3);
    border-radius: 15px;
}

.landing .landing-wrap .landing-box .loop-img-box {
    margin: 48px 0 76px;
}

.landing .landing-wrap .landing-box .loop-img-box div {
    width: 972px;
    pointer-events: none;
}

.landing .landing-wrap .landing-box .loop-img-box div button {
    display: none !important;
}

.landing .landing-wrap .landing-box .loop-img-box div img {
    float: left;
    width: 144px;
    margin: 0 9px;
}

.landing .landing-wrap .landing-box .loop-img-box div img.under {
    margin-top: 48px
}

.landing .landing-wrap .landing-box .btn-box {
    text-align: center;
    padding: 0 24px
}

.landing .landing-wrap .landing-box .btn-box a {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #fff;
    letter-spacing: -.3px;
    background-color: #ff0086;
    border-radius: 28px;
    line-height: 56px;
    font-weight: 600;
}

.landing .landing-wrap .landing-box .btn-box.white a {
    background-color: #fff;
    color: #000
}

.landing .landing-wrap .landing-box .btn-box span {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: #666;
    letter-spacing: -.3px;
    margin-top: 12px
}

.landing .landing-wrap .landing-box:nth-child(1) .tit-box {
    margin-top: 44px
}

.landing .landing-wrap .landing-box:nth-child(2) .tit-box h2,
.landing .landing-wrap .landing-box:nth-child(2) .tit-box h2 span {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: -.47px;
    font-weight: 600;
}

.landing .landing-wrap .landing-box .on-air-img {
    padding: 24px;
    margin-top: 60px;
}

.landing .landing-wrap .landing-box .loop-img-box.gradient ol {
    width: 504px;
    position: relative;
    pointer-events: none;
}

.landing .landing-wrap .landing-box .loop-img-box.gradient ol:before {
    content: '';
    width: 100%;
    height: 120px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.landing .landing-wrap .landing-box .loop-img-box.gradient ol li {
    float: left;
    width: 208px;
    margin: 0 12px;
}

.landing .landing-wrap .landing-box .download-btn {
    margin-top: 40px;
    padding: 0 28px
}

.landing .landing-wrap .landing-box .download-btn .app-store-btn {
    display: inline-block;
    width: 50%;
}

.landing .landing-wrap .landing-box .download-btn .google-play-btn {
    display: inline-block;
    width: 50%;
}

.landing .landing-wrap .landing-box .noti-txt {
    margin: 40px 0 60px;
    text-align: center;
}

.landing .landing-wrap .landing-box .noti-txt ol li {
    font-size: 14px;
    line-height: 24px;
    color: #999;
    letter-spacing: -.3px;
}

.landing .landing-wrap .landing-box .noti-txt ol li a {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: #999;
    letter-spacing: -.3px;
    position: relative;
}

.landing .landing-wrap .landing-box .noti-txt ol li a:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #999;
    position: absolute;
    left: 0;
    bottom: 0
}

/* landing - JP */

.landing-jp * {
    font-family: source-han-sans-cjk-ja, 'Noto Sans KR', 'notokr', 'Nanum Gothic', 'Malgun Gothic', sans-serif
}

.landing-jp .header h1 {
    padding: 14px 0;
    transition: .2s;
    opacity: 1;
}

.landing-jp .header h1.hide {
    opacity: 0;
}

.landing-jp .header h1 a {
    display: block;
    width: 138px;
    height: 48px;
    background: url('./images/m_img-brand-mnet-bi-big.png') no-repeat;
    background-size: contain;
    margin: 0 auto;
}

.landing-jp .header .nav-header {
    padding: 14px 0;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
    top: -60px;
    width: 100%;
    z-index: 10;
    transition: .3s;
}

.landing-jp .header .nav-header.show {
    top: 0
}

.landing-jp .header .nav-header a {
    display: block;
    width: 91px;
    height: 32px;
    background: url('./images/m_img-brand-mnet-bi.png') no-repeat;
    background-size: contain;
    margin: 0 auto;
}

.landing-jp .fix-header {
    padding: 14px 24px;
    background-color: #151515;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

.landing-jp .fix-header .left-box {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 99px);
}

.landing-jp .fix-header .left-box img {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: top
}

.landing-jp .fix-header .left-box p {
    display: inline-block;
    width: calc(100% - 52px);
    margin-left: 12px;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    word-break: break-all;
}

.landing-jp .fix-header .left-box p span {
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: #666;
}

.landing-jp .fix-header .right-box {
    display: inline-block;
    vertical-align: top;
    width: 85px;
    margin: 4px 0 4px 14px;
}

.landing-jp .fix-header .right-box a {
    display: block;
    width: 100%;
    line-height: 32px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    background-color: #ff0086;
    border-radius: 16px;
}

.landing-jp .fix-header .right-box .blue-btn {
    width: auto;
    background-color: #3581ff;
    margin-left: auto;
}

.landing-jp .landing-wrap {
    padding-bottom: 60px
}

.landing-jp .landing-wrap .landing-box .tit-box {
    padding: 0 24px;
    text-align: center;
    margin-top: 120px
}

.landing-jp .landing-wrap .landing-box .tit-box h2 {
    font-size: 18px;
    color: #fff;
    line-height: 26px;
}

.landing-jp .landing-wrap .landing-box .tit-box h2 span {
    display: inline-block;
    font-size: 18px;
    color: #ff0086;
    line-height: 26px;
}

.landing-jp .landing-wrap .landing-box .tit-box h3 {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    letter-spacing: -.3px;
    margin-top: 30px
}

.landing-jp .landing-wrap .landing-box .tit-box h3 span {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    color: #ff0086;
    letter-spacing: -.3px;
}

.landing-jp .landing-wrap .landing-box .tit-box p {
    margin-top: 23px;
    font-size: 16px;
    line-height: 24px;
    color: #999
}

.landing-jp .landing-wrap .landing-box .tit-box img {
    width: 96px;
    height: 96px;
    display: inline-block;
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.3);
    border-radius: 15px;
}

.landing-jp .landing-wrap .landing-box .loop-img-box {
    margin: 48px 0 76px;
}

.landing-jp .landing-wrap .landing-box .loop-img-box > div {
    width: 972px;
    pointer-events: none;
}

.landing-jp .landing-wrap .landing-box .loop-img-box .slick-slide {
    /*float: left;*/
    width: 144px;
    margin: 0 9px;
}

.landing-jp .landing-wrap .landing-box .loop-img-box .slick-slide img.under {
    margin-top: 48px
}

.landing-jp .landing-wrap .landing-box .btn-box {
    text-align: center;
    padding: 0 24px
}

.landing-jp .landing-wrap .landing-box .btn-box a {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #fff;
    letter-spacing: -.3px;
    background-color: #ff0086;
    border-radius: 28px;
    line-height: 56px;
    font-weight: 600;
}

.landing-jp .landing-wrap .landing-box .btn-box.white a {
    background-color: #fff;
    color: #000
}

.landing-jp .landing-wrap .landing-box .btn-box span {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: #666;
    letter-spacing: -.3px;
    margin-top: 12px
}

.landing-jp .landing-wrap .landing-box:nth-child(1) .tit-box {
    margin-top: 44px
}

.landing-jp .landing-wrap .landing-box:nth-child(2) .tit-box h2,
.landing-jp .landing-wrap .landing-box:nth-child(2) .tit-box h2 span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
}

.landing-jp .landing-wrap .landing-box:nth-child(3) .tit-box h2,
.landing-jp .landing-wrap .landing-box:nth-child(3) .tit-box h2 span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
}

.landing-jp .landing-wrap .landing-box .on-air-img {
    padding: 24px;
    margin-top: 60px;
}

.landing-jp .landing-wrap .landing-box .loop-img-box.gradient > div {
    width: 504px;
    position: relative;
    pointer-events: none;
}

.landing-jp .landing-wrap .landing-box .loop-img-box.gradient > div:before {
    content: '';
    width: 100%;
    height: 120px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.landing-jp .landing-wrap .landing-box .loop-img-box.gradient .slick-slide {
    /*float: left;*/
    width: 208px;
    margin: 0 12px;
}

.landing-jp .landing-wrap .landing-box .download-btn {
    margin-top: 40px;
    padding: 0 28px
}

.landing-jp .landing-wrap .landing-box .download-btn .app-store-btn {
    display: inline-block;
    width: 50%;
}

.landing-jp .landing-wrap .landing-box .download-btn .google-play-btn {
    display: inline-block;
    width: 50%;
}

.landing-jp .landing-wrap .landing-box .sns-box {
    margin: 80px auto 30px;
    text-align: center;
    padding: 0 24px;
}

.landing-jp .landing-wrap .landing-box .sns-box ol li {
    width: 40px;
    height: 40px;
    margin: 0 16px;
    display: inline-block;
}

.landing-jp .landing-wrap .landing-box .sns-box ol li a {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.landing-jp .landing-wrap .landing-box .sns-box ol li.twitter-ico a {
    background-image: url('./images/logo-sns-twitter-jp.png');
}

.landing-jp .landing-wrap .landing-box .sns-box ol li.instagram-ico a {
    background-image: url('./images/logo-sns-instragram-jp.png');
}

.landing-jp .landing-wrap .landing-box .sns-box ol li.youtube-ico a {
    background-image: url('./images/logo-sns-youtube-jp.png');
}

.landing-jp .landing-wrap .landing-box .footer-box {
    margin-top: 40px;
    text-align: center;
    padding: 0 24px;
}

.landing-jp .landing-wrap .landing-box .footer-box ol li {
    width: 100%;
}

.landing-jp .landing-wrap .landing-box .footer-box ol li a {
    font-size: 14px;
    line-height: 36px;
    color: #666;
    display: block;
}

@keyframes rotate_image {
    100% {
        transform: rotate(360deg);
    }
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}
